<template>
  <div id="app">
    <router-view />
    <LoadingGuard />
    <MeetingWindow />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue'

import v1Store from '@/store'
import LoadingGuard from '@/v1.5/features/auth/components/loading-guard/loading-guard.vue'
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import MeetingWindow from '@/v1.5/features/meetings/components/meeting-window/meeting-window.vue'
import useRouter from '@/v1.5/hooks/use-router.hook'
import { identifyUser } from '@/v1.5/lib/posthog'
import { invalidateConversations } from '@/v1.5/utils/lib/vue-query'

const router = useRouter()
onBeforeMount(() => {
  // call v1 auth data
  if (router?.currentRoute.meta?.requiresAuth) {
    v1Store.dispatch('callAuthData')
  }
})

const { accountRef } = useAccount()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const accountIdRef = computed(() => accountRef.value?.id)
const accountSupportIdRef = computed(() => accountRef.value?.supportId)
const accountFirstNameRef = computed(() => accountRef.value?.firstName)
const accountLastNameRef = computed(() => accountRef.value?.lastName)
const accountEmailRef = computed(() => accountRef.value?.email)
const accountPhoneRef = computed(() => accountRef.value?.phone)
const accountRoleRef = computed(() => accountRef.value?.role)

const socketRef = computed(() => v1Store.getters.socket)

// listen to sockets
watch(
  accountIdRef,
  (accountId) => {
    if (accountId) {
      listenSocket(accountId)
    }
  },
  { immediate: true, flush: 'post' },
)

// identify user to posthog
watch(
  accountRef,
  (account) => {
    if (account) {
      identifyUser(account)
    }
  },
  { immediate: true, flush: 'post' },
)

// add visitor id to brevo
watch(accountSupportIdRef, (supportId) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (supportId && window.BrevoConversationsSetup) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.BrevoConversationsSetup.visitorId = supportId
  }
})

// add user data to brevo
watch(
  [accountEmailRef, accountFirstNameRef, accountLastNameRef, accountPhoneRef, accountRoleRef],
  ([email, firstName, lastName, phone, role]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.BrevoConversationsSetup) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.BrevoConversations('updateIntegrationData', {
        email: email ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        phone: phone ?? '',
        role: role ?? '',
      })
    }
  },
)

// listen to socket
function listenSocket(accountId: string) {
  socketRef.value.off(`USER_${accountId}`)
  socketRef.value.on(`USER_${accountId}`, (payload: any) => {
    switch (payload.action) {
      case 'SEND_MESSAGE':
        // invalidate the conversations query when the socket receives a new message
        invalidateConversations(selectedGroupIdRef.value!)
        break
      default:
        break
    }
  })
}
</script>
