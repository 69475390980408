import imageCompression from 'browser-image-compression'

import type {
  DoesUserExistDTOType,
  DoesUserExistResponseType,
  LoginDTOType,
  LoginResponseType,
  RegisterDTOType,
  ResetPasswordDTOType,
  UpdateUserDescriptionDTOType,
  UpdateUserDTOType,
  V1AccountDataType,
  V1AuthUserType,
  V1ChangePasswordDTOType,
  V1PhoneType,
  V1UpdatePhoneDTOType,
  V1UpdateUserDTOType,
  V1UpdateUserIdentityDTOType,
  V1UpdateUserPictureDTOType,
  V1UpdateUserRoleDTOType,
} from '@/v1.5/features/auth/types'
import fetchClient from '@/v1.5/lib/fetch'

export async function login(data: LoginDTOType): Promise<LoginResponseType> {
  return fetchClient('/v1/auth/login', {
    method: 'POST',
    body: JSON.stringify({ email: data.email, password: data.password }),
  })
}

export async function register(data: RegisterDTOType): Promise<void> {
  return fetchClient('/v1/auth/register', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function forgotPassword(email: string): Promise<void> {
  return fetchClient('/v1/auth/forgotPassword', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
}

export async function resetPassword(data: ResetPasswordDTOType): Promise<void> {
  return fetchClient('/v1/auth/updateForgotPassword', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const V1_AUTH_USER_QUERY_KEY = 'v1-auth-user'
export async function V1GetAuthUser(): Promise<V1AuthUserType> {
  return fetchClient('/v1/auth/user', {
    method: 'GET',
  })
}

export const V1_PHONE_QUERY_KEY = 'v1-phone'
// TODO: user id does not exist
export async function V1GetPhone(userId: string): Promise<V1PhoneType> {
  return fetchClient(`/v1/user/${userId}/phone`, {
    method: 'GET',
  })
}

export const V1_USER_DATA_QUERY_KEY = 'v1-user-data'
export async function getUserData(): Promise<V1AccountDataType> {
  return fetchClient('/v1/user', { method: 'GET' })
}

export async function V1updateUser(data: V1UpdateUserDTOType): Promise<void> {
  return fetchClient('/v1/user/user', {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export async function V1updateUserIdentity(data: V1UpdateUserIdentityDTOType): Promise<void> {
  return fetchClient('/v1/user/identity', {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export async function doesUserExistMutation(data: DoesUserExistDTOType): Promise<DoesUserExistResponseType> {
  return fetchClient(`/v1/auth/exist`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function V1UpdateUserRole(data: V1UpdateUserRoleDTOType) {
  return fetchClient('/v1/user/role', {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export async function V1UpdateUserPhone(data: V1UpdatePhoneDTOType) {
  return fetchClient(`/v1/user/${data.userId}/phone`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export async function V1UpdateUserPicture(data: V1UpdateUserPictureDTOType) {
  return fetchClient(
    '/v1/user/profil/picture',
    {
      method: 'PATCH',
      body: data,
    },
    true,
  )
}

export async function V1UpdateUserDescriptionMutation(data: UpdateUserDescriptionDTOType) {
  return fetchClient(`/v1/user/${data.userId}/description`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function updateUserMutation(data: UpdateUserDTOType): Promise<void> {
  if (data.email) {
    return V1updateUser({
      email: data.email,
    })
  }

  if (data.firstName && data.lastName) {
    return V1updateUserIdentity({
      firstname: data.firstName,
      lastname: data.lastName,
    })
  }

  if (data.role) {
    return V1UpdateUserRole({
      role: data.role,
    })
  }

  if (data.phone && data.userId) {
    return V1UpdateUserPhone({
      phone: data.phone,
      userId: data.userId,
    })
  }

  if (data.picture) {
    // compress the file
    const compressedFile = await imageCompression(data.picture, {
      maxSizeMB: 0.29,
      maxWidthOrHeight: 400,
    })

    // formdata
    const formData = new FormData()
    formData.append('files', compressedFile)
    return V1UpdateUserPicture(formData)
  }
}

export async function changePasswordMutation({
  userId,
  oldPassword,
  password,
  confirmPassword,
}: V1ChangePasswordDTOType): Promise<void> {
  return fetchClient(`/v1/auth/password/${userId}`, {
    method: 'PATCH',
    body: JSON.stringify({ oldPassword, password, confirmPassword }),
  })
}
