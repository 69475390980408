<template>
  <GridBlockBase id="mentor-profile" :title="t('dashboard.mentee.mentorProfile.title')">
    <template #icon>
      <MentorIcon color="black" filled />
    </template>
    <template #body>
      <LoadingContent v-if="(isPendingRef || isGroupPendingRef) && groupsRef?.length > 0" />
      <EmptyContent v-else-if="!mentorDataRef" :text="t('dashboard.mentee.mentorProfile.noMentor')">
        <template #icon>
          <MentorIcon color="black" />
        </template>
        <template #action v-if="programRef?.settings.isMatchingEnabled">
          <CustomButton
            usage="button"
            color="primary"
            :text="t('dashboard.mentee.mentorProfile.findMentor')"
            @emit:click="openMatchingModal"
          >
            <template #icon>
              <SearchIcon color="black" filled />
            </template>
          </CustomButton>
        </template>
      </EmptyContent>
      <div class="mentor-card" v-else>
        <div class="mentor-card__info-wrapper">
          <Avatar :user="mentorDataRef" />
          <div class="mentor-card__info-wrapper__info">
            <span class="user-name">{{ mentorDataRef?.firstName }} {{ mentorDataRef?.lastName }}</span>
            <p class="user-sub-info">{{ t('common.mentor.capitalize') }}</p>
          </div>
        </div>
        <div class="mentor-card__more-options" data-action="user-card" :data-attached="JSON.stringify(mentorDataRef)">
          <MoreOptionsIcon color="black" />
        </div>
      </div>
      <div v-if="mentorDataRef" class="c-info-wrapper">
        <p class="c-info-label">
          <strong>{{ t('dashboard.mentee.mentorProfile.matchingCriteria') }}</strong>
        </p>
        <p class="c-info-text">
          {{ mentorDataRef.matchingCriteria || t('dashboard.mentee.mentorProfile.matchingCriteria.empty') }}
        </p>
      </div>
      <div v-if="mentorDataRef" class="c-info-wrapper">
        <p class="c-info-label">
          <strong>{{ t('user.profile.description') }}</strong>
        </p>
        <div v-if="hasDescriptionRef" class="c-info-text mentor-description" id="mentor-profile-description"></div>
        <p v-else class="c-info-text mentor-description">{{ t('dashboard.mentee.mentorProfile.description.empty') }}</p>
      </div>
    </template>
  </GridBlockBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useGroup from '@/v1.5/features/groups/hooks/use-group.hook'
import useGroups from '@/v1.5/features/groups/hooks/use-groups.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { getMentor } from '@/v1.5/features/groups/utils'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import EmptyContent from '@/v1.5/features/ui/components/empty-content/empty-content.vue'
import GridBlockBase from '@/v1.5/features/ui/components/grid-block-base/grid-block-base.vue'
import MentorIcon from '@/v1.5/features/ui/components/icons/mentor-icon.vue'
import MoreOptionsIcon from '@/v1.5/features/ui/components/icons/more-options-icon.vue'
import SearchIcon from '@/v1.5/features/ui/components/icons/search-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import Avatar from '@/v1.5/features/users/components/avatar/avatar.vue'
import useProfileDescription from '@/v1.5/features/users/hooks/use-profile-description.hook'
import useUser from '@/v1.5/features/users/hooks/use-user.hook'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const programIdRef = selectGetSelectedProgramId(programsStore)
const { programRef } = useProgram(programIdRef)
const selectedGroupId = selectGetSelectedGroupId(groupsStore)
const { groupsRef } = useGroups()
const { groupRef, isPendingRef: isGroupPendingRef } = useGroup(selectedGroupId)

const mentorRef = computed(() => groupRef.value?.users.find(getMentor))
const mentorIdRef = computed(() => mentorRef.value?.id)
const { userRef: mentorDataRef, isPendingRef } = useUser(mentorIdRef)

const { hasDescriptionRef } = useProfileDescription(mentorIdRef, 'mentor-profile-description')

const openMatchingModal = () => {
  modalsStore.toggleShouldHideMatchingModals(false)
}
</script>

<style lang="scss">
@import './dashboard-mentor-profile.scss';
</style>
