<template>
  <section class="profile-content">
    <GridBlockBase id="profile-information">
      <template #body>
        <div class="profile-content__content">
          <div class="user-profile-info" v-if="userRef">
            <div class="user-profile__info-card">
              <div class="user-profile__info-card__avatar">
                <Avatar :user="userRef" />
              </div>
              <div class="user-profile__info-card__info">
                <p class="info-role" v-if="userRef.role">{{ t(userRolesNamesMap[userRef.role]) }}</p>
                <p class="info-name">{{ userRef.firstName }} {{ userRef.lastName }}</p>
              </div>
            </div>
            <div class="c-info-wrapper">
              <p class="c-info-label">
                <strong>{{ t('user.profile.description') }}</strong>
              </p>
              <div v-if="hasDescriptionRef" class="user-description" id="account-description"></div>
              <p v-else class="c-info-text user-description">{{ t('user.profile.description.empty') }}</p>
            </div>
          </div>
        </div>
        <div class="profile-content__footer">
          <div class="c-btns-container c-btn-center">
            <CustomButton color="primary" :text="t('auth.profile.edit')" usage="button" @emit:click="editProfile">
              <template #icon>
                <EditIcon color="white" />
              </template>
            </CustomButton>
          </div>
        </div>
      </template>
    </GridBlockBase>
    <GridBlockBase id="profile-credentials">
      <template #body>
        <div class="profile-credentials__content">
          <div class="c-info-wrapper" v-if="accountRef">
            <p class="c-info-label">
              <strong> {{ t('auth.profile.phone') }} </strong>
            </p>
            <p class="c-info-text">{{ phoneRef || t('auth.profile.phone.empty') }}</p>
          </div>
          <div v-if="!accountRef?.hasSAML" class="c-info-wrapper">
            <p class="c-info-label">
              <strong>{{ t('auth.profile.password') }}</strong>
            </p>
            <p class="c-info-text">****************</p>
          </div>
        </div>
        <div class="profile-credentials__footer">
          <div class="c-btns-container c-btn-center">
            <CustomButton
              color="primary"
              :text="t('auth.profile.phone.modify')"
              usage="button"
              @emit:click="editPhoneNumber"
            >
              <template #icon>
                <EditIcon color="white" />
              </template>
            </CustomButton>
            <CustomButton
              v-if="!accountRef?.hasSAML"
              color="danger"
              :text="t('auth.profile.password.modify')"
              usage="button"
              @emit:click="editPassword"
            >
              <template #icon>
                <EditIcon color="white" />
              </template>
            </CustomButton>
          </div>
        </div>
      </template>
    </GridBlockBase>
  </section>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import usePhone from '@/v1.5/features/auth/hooks/use-phone.hook'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import GridBlockBase from '@/v1.5/features/ui/components/grid-block-base/grid-block-base.vue'
import EditIcon from '@/v1.5/features/ui/components/icons/edit-icon.vue'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import Avatar from '@/v1.5/features/users/components/avatar/avatar.vue'
import useProfileDescription from '@/v1.5/features/users/hooks/use-profile-description.hook'
import useUser from '@/v1.5/features/users/hooks/use-user.hook'
import { userRolesNamesMap } from '@/v1.5/features/users/utils'
import { useI18n } from '@/v1.5/lib/i18n'

const { t } = useI18n()

const { accountRef } = useAccount()
const { phoneRef } = usePhone()

const accountIdRef = computed(() => accountRef.value?.id)
const { userRef } = useUser(accountIdRef)

const { hasDescriptionRef } = useProfileDescription(accountIdRef, 'account-description')

function editProfile() {
  modalsStore.toggleModal('profile-information', true)
}

function editPhoneNumber() {
  modalsStore.toggleModal('phone', true)
}

function editPassword() {
  modalsStore.toggleModal('change-password', true)
}
</script>

<style lang="scss">
@import './profile-content.scss';
</style>
