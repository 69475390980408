import type {
  CheckDeliverableImportationDTOType,
  CheckDeliverableImportationResponseType,
  DeleteDeliverableDTOType,
  DeliverableType,
  GetAllDeliverablesDTOType,
  GetDeliverableByUserIdDTOType,
  GetDeliverableURLDTOType,
  GetDeliverableURLResponseType,
  GetSessionDTOType,
  GetSessionResponseType,
  GetSessionsDTOType,
  GetSessionsResponseType,
  ReviewSessionDTOType,
  UploadDeliverableDTOType,
  UploadDeliverableResponseType,
  V1AddDeliverableRateDTOType,
  V1AddSessionReportDTOType,
  V1AdminExportSessionsDTOType,
  V1AdminExportSessionsResponseType,
  V1ConfirmPresenceDTOType,
  V1CreateSessionDTOType,
  V1CreateSessionResponseType,
  V1ReviewSessionDTOType,
  V1UpdateSessionStatusDTOType,
} from '@/v1.5/features/sessions/types'
import fetchClient from '@/v1.5/lib/fetch'

export const V1_SESSIONS_QUERY_KEY = 'v1-sessions'
export async function getSessions(data: GetSessionsDTOType): Promise<GetSessionsResponseType> {
  return fetchClient(
    `/v2/sessions?programId=${data.programId}&periodId=${data.periodId}&mentorshipGroupId=${data.mentorshipGroupId}&page=${data.page}&perPage=${data.perPage}&filterEmail=${data.filterEmail}`,
    {
      method: 'GET',
    },
  )
}

export const SESSION_QUERY_KEY = 'v1-session'
export async function getSession(data: GetSessionDTOType): Promise<GetSessionResponseType> {
  return fetchClient(`/v2/sessions/${data.id}`, {
    method: 'GET',
  })
}

export async function V1AdminExportSessions(
  data: V1AdminExportSessionsDTOType,
): Promise<V1AdminExportSessionsResponseType> {
  return fetchClient(`/v2/sessions/export?programId=${data.programId}&periodId=${data.periodId}`, {
    method: 'GET',
  })
}

export async function V1CreateSessionMutation(data: V1CreateSessionDTOType): Promise<V1CreateSessionResponseType> {
  return fetchClient(`/v2/sessions`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function V1UpdateSessionMutation(data: any): Promise<void> {
  return fetchClient(`/v2/sessions/${data.sessionId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export async function V1UpdateSessionStatusMutation(data: V1UpdateSessionStatusDTOType): Promise<void> {
  return fetchClient(`/v2/sessions/${data.sessionId}/status`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export async function reviewSessionMutation(data: ReviewSessionDTOType): Promise<void> {
  return fetchClient(`/v2/sessions/${data.sessionId}/review`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function V1AddSessionReviewMutation(data: V1ReviewSessionDTOType): Promise<void> {
  return fetchClient(`/v1/user/${data.userId}/session/${data.sessionId}/report`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function uploadDeliverableMutation(
  data: UploadDeliverableDTOType,
): Promise<UploadDeliverableResponseType> {
  return fetchClient(`/v2/sessions/${data.sessionId}/users/${data.userId}/deliverable`, {
    method: 'POST',
    body: JSON.stringify({ mimetype: data.mimetype }),
  })
}

export const DELIVERABLES_QUERY_KEY = 'deliverables'
export async function getAllDeliverables(data: GetAllDeliverablesDTOType): Promise<DeliverableType[]> {
  return fetchClient(`/v2/sessions/${data.sessionId}/deliverables`, {
    method: 'GET',
  })
}

export const DELIVERABLE_QUERY_KEY = 'deliverable'
export async function getDeliverableByUserId(data: GetDeliverableByUserIdDTOType): Promise<DeliverableType> {
  return fetchClient(`/v2/sessions/${data.sessionId}/users/${data.userId}/deliverable`, {
    method: 'GET',
  })
}

export async function getDeliverableURL(data: GetDeliverableURLDTOType): Promise<GetDeliverableURLResponseType> {
  return fetchClient(`/v2/sessions/${data.sessionId}/users/${data.userId}/deliverables/${data.deliverableId}/url`, {
    method: 'GET',
  })
}

export async function checkDeliverableImportationMutation(
  data: CheckDeliverableImportationDTOType,
): Promise<CheckDeliverableImportationResponseType> {
  return fetchClient(`/v2/sessions/deliverables/check/is-imported?deliverableId=${data.deliverableId}`, {
    method: 'GET',
  })
}

export async function deleteDeliverableMutation(data: DeleteDeliverableDTOType): Promise<void> {
  return fetchClient(`/v2/sessions/${data.sessionId}/users/${data.userId}/deliverables/${data.deliverableId}`, {
    method: 'DELETE',
  })
}

export async function V1AddDeliverableRateMutation(data: V1AddDeliverableRateDTOType): Promise<void> {
  return fetchClient(`/v2/sessions/${data.sessionId}/users/${data.userId}/deliverable/rate`, {
    method: 'POST',
    body: JSON.stringify({ rate: data.rate }),
  })
}

export async function V1ConfirmPresencesMutation(data: V1ConfirmPresenceDTOType): Promise<void> {
  return fetchClient(`/v2/sessions/presence`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function V1AddSessionReportMutation(data: V1AddSessionReportDTOType): Promise<void> {
  return fetchClient(`/v2/sessions/${data.sessionId}/report`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
