<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <PageOnboarding>
    <template v-slot:right>
      <div class="onboarding__body__form">
        <h1 class="fs-5 fw-normal mb-4 text-center">
          Connexion <br />
          <span class="fs-6">à votre espace {{ $store.getters.appTitle }}</span>
        </h1>

        <form @submit.prevent="submitForm">
          <label for="email" class="form-label">E-mail</label>
          <div class="input-group has-validation mb-4">
            <input
              id="email"
              class="form-control"
              type="email"
              required
              placeholder="Saisissez votre adresse e-mail.."
              autocomplete="username"
              v-model="email"
            />
          </div>

          <label for="password" class="form-label">Mot de passe</label>
          <div class="input-group has-validation">
            <input
              ref="password"
              class="form-control"
              type="password"
              required
              placeholder="Saisissez votre mot de passe"
              v-model="password"
              autocomplete="current-password"
              id="password"
            />
          </div>
          <p class="form-text">
            <router-link
              class="text-decoration-none"
              :to="{ name: 'ForgotPassword' }"
              >Mot de passe oublié</router-link
            >
          </p>

          <div class="d-grid mb-2">
            <button type="submit" class="btn btn-primary mt-3">
              Se connecter
            </button>
          </div>

          <p class="form-text">
            <router-link class="text-decoration-none" :to="{ name: 'Login' }"
              >{{ '<' }} Retour
            </router-link>
          </p>

          <div class="d-flex justify-content-between mt-3 text-sub-btn">
            <template v-if="$store.getters.blockRegistration == 'false'">
              <p class="form-text text-center w-100">
                Envie de nous rejoindre ?<router-link
                  class="text-decoration-none"
                  :to="{
                    name: 'Register',
                  }"
                >
                  Créer un compte
                </router-link>
              </p>
            </template>
          </div>
        </form>
      </div>
    </template>
  </PageOnboarding>
</template>

<script>
import PageOnboarding from '@/components/PageOnboarding.vue'
import { SetLoginData } from '@/mixines/SetLoginData'

export default {
  name: 'EmailLogin',
  data() {
    return {
      email: null,
      password: null,
    }
  },
  components: {
    PageOnboarding,
  },
  mixins: [SetLoginData],
  methods: {
    async submitForm() {
      const login = await this.$store.dispatch('authLogin', {
        email: this.email,
        password: this.password,
      })
      if (login.error) {
        return this.$toast.error(login.error, {
          timeout: 2000,
        })
      }
      // redirect to the page where the user was before the login
      if (this.$route.query.from) {
        this.$router.push(this.$route.query.from).catch(() => {})
        return
      }
      // full clean reload on auth login
      window.location.href = '/'

      // if (this.$store.getters.matching == 'true') {
      //   await this.$store.dispatch('fetchUser')
      //   return this.$router.push({ name: 'IndexMentoringGroup' })
      // } else {
      //   return this.$router.push({ name: 'MentorList' })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding {
  &__head {
    &__text {
      max-width: 25rem;
    }
  }
  &__body {
    &__form {
      width: 25rem;
      @media all and (max-width: 425px) {
        width: 300px;
      }
    }
  }
}
</style>
