<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Modal
    :onCloseFn="closePopup"
    class="popup"
    name="sessionDetails"
    title="Détails de la session"
  >
    <div class="content" v-if="isLoading">
      <p>Chargement...</p>
    </div>
    <div class="content" v-else>
      <div class="block">
        <div>
          <p class="block__subtitle">
            <strong>Mentor :</strong>
          </p>
          <p class="me-2">
            <span class="p-2 fs-6 badge text-dark">
              {{
                session?.mentorshipGroup?.users?.find((user) => user.role === 'ROLE_MENTOR')
                  ?.identity.firstname
              }}
              {{
                session?.mentorshipGroup?.users?.find((user) => user.role === 'ROLE_MENTOR')
                  ?.identity.lastname
              }}
            </span>
          </p>
        </div>
        <div>
          <p class="block__subtitle">
            <strong>Présence des mentorés :</strong>
          </p>
          <div v-if="session.presences?.length" class="d-flex flex-wrap">
            <p
              class="me-2"
              v-for="presence in session.presences"
              :key="presence.userId"
            >
              <span
                class="p-2 fs-6 badge"
                :class="presence.isPresent ? 'bg-success' : 'bg-danger'"
              >
                {{
                  session.mentorshipGroup?.users.find((user) => user.id === presence.userId)
                    ?.identity.firstname
                }}
                {{
                  session.mentorshipGroup?.users.find((user) => user.id === presence.userId)
                    ?.identity.lastname
                }}
              </span>
            </p>
          </div>
          <p v-else>Le mentor doit confirmer la présence de ses mentorés</p>
        </div>
        <div>
          <p class="block__subtitle">
            <strong> Compte-rendu mentor :</strong>
          </p>
          <p>
            <span v-if="session.report?.comment">
              {{ session.report.comment }}
            </span>
            <span v-else> Aucun compte-rendu </span>
          </p>
        </div>
        <div>
          <p class="block__subtitle">
            <strong> Moyenne des notes des rendus :</strong>
          </p>
          <p v-if="deliverables.find((d) => typeof d.rating === 'number')">
            Moyenne :
            {{
              Math.round(
                (deliverables.reduce((acc, d) => acc + d.rating, 0) /
                  deliverables.length) *
                  10
              ) / 10
            }}
            / 20
          </p>
          <p v-else>Aucune note pour le moment</p>
        </div>
        <div>
          <p class="block__subtitle">
            <strong> Rendus des mentorés :</strong>
          </p>
          <div class="deliverables-container" v-if="deliverables.length">
            <template
              v-for="user in session.mentorshipGroup?.users.filter(
                (u) => u.role === 'ROLE_STUDENT'
              )"
            >
              <div
                class="deliverable"
                :key="user.userId"
                v-if="deliverables.find((d) => d.userId === user.id)?.isImported"
              >
                <span>
                  {{ user?.identity?.firstname }}
                  {{ user?.identity?.lastname }}
                </span>
                <span class="fw-bold">Travail rendu :</span>
                <button
                  class="btn btn-primary btn-sm"
                  @click="downloadDeliverable(user.id)"
                >
                  <span class="material-symbols-outlined"> description </span>
                  <span>Télécharger</span>
                </button>
                <span
                  v-if="
                    typeof deliverables.find((d) => d.userId === user.id)?.rating === 'number'
                  "
                  >{{
                    deliverables.find((d) => d.userId === user.id)?.rating
                  }}
                  / 20</span
                >
                <span v-else>Aucune note</span>
              </div>
              <div v-else class="no-deliverable">
                <span>
                  {{ user?.identity.firstname }}
                  {{ user?.identity?.lastname }}
                </span>
                <span class="fw-bold">Aucun travail rendu.</span>
                <span
                  v-if="
                    typeof deliverables.find((d) => d.userId === user.id)?.rating === 'number'
                  "
                  >{{
                    deliverables.find((d) => d.userId === user.id)?.rating
                  }}
                  / 20</span
                >
                <span v-else>Aucune note</span>
              </div>
            </template>
          </div>
          <div v-else>
            <p>Aucun rendu pour le moment</p>
          </div>
        </div>
      </div>
      <div class="block">
        <div>
          <p class="block__subtitle">
            <strong> Notes et avis :</strong>
          </p>
          <ul v-if="session.reviews?.length" class="reviews-container">
            <li
              class="review"
              v-for="review in session.reviews"
              :key="review.userId"
            >
              <div class="review__user-info">
                <div class="d-flex align-items-center">
                  <div class="profile-picture_session-status-badge">
                    <ProfilePicture
                      class="profile-picture"
                      :src="
                        session.mentorshipGroup?.users.find(
                          (user) => user.id === review.userId
                        )?.identity.picture
                      "
                      :firstname="
                        session.mentorshipGroup?.users.find(
                          (user) => user.id === review.userId
                        )?.identity.firstname
                      "
                      :lastname="
                        session.mentorshipGroup?.users.find(
                          (user) => user.id === review.userId
                        )?.identity.lastname
                      "
                      :width="40"
                      :height="40"
                    />
                  </div>
                  <div>
                    <router-link
                      class="ms-2"
                      :to="{
                        name: 'IndexProfile',
                        params: { id: review.userId },
                      }"
                    >
                      {{
                        session.mentorshipGroup?.users.find(
                          (user) => user.id === review.userId
                        )?.identity.firstname
                      }}
                      {{
                        session.mentorshipGroup?.users.find(
                          (user) => user.id === review.userId
                        )?.identity.lastname
                      }}
                    </router-link>
                  </div>
                </div>
                <span>
                  <span
                    class="material-symbols-outlined filled"
                    v-for="(star, index) in new Array(review.grade).fill(0)"
                    :key="review.userId + index"
                  >
                    star
                  </span>
                  <span
                    class="material-symbols-outlined"
                    v-for="(star, index) in new Array(5 - review.grade).fill(0)"
                    :key="index + review.userId"
                  >
                    star
                  </span>
                </span>
              </div>
              <div class="review__text">
                <p v-if="review.review">"{{ review.review }}"</p>
              </div>
            </li>
          </ul>
          <p v-else>En attente des avis mentorés</p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'

import ProfilePicture from '../../ProfilePicture.vue'

import Modal from '@/components/Modal.vue'

export default {
  name: 'SessionDetailsPopup',
  data() {
    return {
      session: null,
      deliverables: [],
    }
  },
  computed: {
    isLoading() {
      return !this.session
    },
  },
  methods: {
    ...mapActions(['switchModal', 'setSelectedSessionId', 'getDeliverable', 'getAllDeliverables']),
    // close the popup
    closePopup() {
      this.switchModal({ name: 'sessionDetails', show: false })
      this.setSelectedSessionId(null)
      this.resetPopupData()
    },

    resetPopupData() {
      // reset internal state
      this.session = null
    },
    async downloadDeliverable(userId) {
      const data = await this.getDeliverable({
        userId: userId,
        sessionId: this.$store.getters.selectedSessionId,
        deliverableId: this.deliverables.find((d) => d.userId === userId).id,
      })
      const url = data.url

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.download = ''
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        link.remove()
      }, 200)
    },
  },
  watch: {
    async '$store.getters.selectedSessionId'() {
      this.session = this.$store.getters.adminSessions.find(
        (session) => session.id === this.$store.getters.selectedSessionId,
      )
      if (this.$store.getters.selectedSessionId) {
        const deliverables = await this.getAllDeliverables(this.$store.getters.selectedSessionId)
        this.deliverables = deliverables
      }
    },
  },
  async mounted() {
    this.session = this.$store.getters.adminSessions.find(
      (session) => session.id === this.$store.getters.selectedSessionId,
    )
  },
  components: {
    Modal,
    ProfilePicture,
  },
}
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  position: relative;
  &-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 32px;
  padding: 24px;
  width: 100%;

  .block {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 16px;
    width: 100%;
    &__title {
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;
    }
    &__subtitle {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 16px;
      li {
        display: flex;
        flex-direction: column;
        .attendance-checkbox {
          width: 24px;
          height: 24px;
        }
      }
    }
    .reviews-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      .review {
        position: relative;
        padding: 16px;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &__text {
          flex-grow: 1;
          display: flex;
          white-space: pre;
        }

        &__user-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
        }

        &:not(:last-child) {
          &:before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: calc(100% - 24px);
            height: 1px;
            background-color: var(--bs-gray);
          }
        }

        .material-symbols-outlined.filled {
          font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24;
          color: #e0c34c;
        }
      }
    }
  }
}
</style>
