<template>
  <Popover :popover="groupsPopoverRef">
    <template #content>
      <PopoverRow
        v-for="group in groupsRef"
        :key="group.id"
        :is-active="selectedGroupIdRef === group.id"
        @emit:click="(e) => handleSwitchGroup(e, group.id)"
      >
        <template #content>
          <CheckFullIcon color="primary" :is-checked="selectedGroupIdRef === group.id" />
          <div class="group-info">
            <NotificationsBadge
              :numberOfNotifications="
                allNotificationsPerGroupRef?.find((notifPerGroup) => notifPerGroup.mentorshipGroupId === group.id)
                  ?.notifications.total
              "
            />
            <p>{{ group?.name }}</p>
            <div class="users-list" v-if="group.users.length">
              <UserBadge :user="group.users.find(getMentor)" noStatus variant="small" />
              <UsersBadgesStack :users="group.users.filter(getMentees)" noStatus variant="small" />
            </div>
          </div>
          <div v-if="accountRef?.role === RoleEnumType.MENTOR" class="group-more-actions">
            <MoreOptionsIcon color="black" />
          </div>
        </template>
      </PopoverRow>
      <PopoverRow
        v-if="accountRef?.role === RoleEnumType.MENTEE && programRef?.settings.isMatchingEnabled"
        @emit:click="(e) => handleSwitchGroup(e, 'new')"
      >
        <template #content>
          <AddGroupIcon color="black" isFilled />
          <p>{{ t('groups.dropdown.newGroup') }}</p>
        </template>
      </PopoverRow>
    </template>
  </Popover>
</template>

<script setup lang="ts">
import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import useGroups from '@/v1.5/features/groups/hooks/use-groups.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { getMentees, getMentor } from '@/v1.5/features/groups/utils'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import UserBadge from '@/v1.5/features/sessions/components/user-badge/user-badge.vue'
import UsersBadgesStack from '@/v1.5/features/sessions/components/users-badges-stack/users-badges-stack.vue'
import AddGroupIcon from '@/v1.5/features/ui/components/icons/add-group-icon.vue'
import CheckFullIcon from '@/v1.5/features/ui/components/icons/check-full-icon.vue'
import MoreOptionsIcon from '@/v1.5/features/ui/components/icons/more-options-icon.vue'
import NotificationsBadge from '@/v1.5/features/ui/components/notifications-badge/notifications-badge.vue'
import Popover from '@/v1.5/features/ui/components/popover/popover.vue'
import PopoverRow from '@/v1.5/features/ui/components/popover/popover-row/popover-row.vue'
import type { GroupSettingsModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import { selectGetGroupsPopover } from '@/v1.5/features/ui/store/popover/popover.selectors'
import popoversStore from '@/v1.5/features/ui/store/popover/popover.store'
import useNotifications from '@/v1.5/hooks/use-notifications.hook'
import { useI18n } from '@/v1.5/lib/i18n'

const groupsPopoverRef = selectGetGroupsPopover(popoversStore)

const { accountRef } = useAccount()
const { groupsRef } = useGroups()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { programRef } = useProgram(selectedProgramIdRef)
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const { allNotificationsPerGroupRef } = useNotifications()

const { t } = useI18n()

function openGroupSettings(groupId: string) {
  console.log('openGroupSettings', groupId)
  modalsStore.toggleModal('group-settings', true)
  modalsStore.updateModalAttachedData<GroupSettingsModalType>('group-settings', { groupId })
}

const handleSwitchGroup = (e: MouseEvent, newGroupId: string) => {
  // if the selected answer is to search for a new group, we open the new matching modal
  if (newGroupId === 'new') {
    modalsStore.toggleModal('new-matching', true)
    popoversStore.togglePopover('groups', false)
    return
  }

  // otherwise, we select the new mentorship group
  const newSelectedGroup = groupsRef.value.find((group) => group.id === newGroupId)
  // select the new mentorship group
  if (newSelectedGroup) {
    groupsStore.setSelectedGroupId(newSelectedGroup.id)
  }

  popoversStore.togglePopover('groups', false)

  if ((e.target as HTMLElement).closest('.group-more-actions')) {
    openGroupSettings(newGroupId)
  }
}
</script>

<style lang="scss">
@import './groups-popover.scss';
</style>
