import { computed, ref, watchEffect } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import useProgram from '@/v1.5/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { slugify } from '@/v1.5/utils/misc/slugify'

export default function useMatchingInformation({
  onSubmitCallback,
}: {
  onSubmitCallback: (filledCriteria: string) => void
}) {
  const MATCHING_CRITERIA_REGEX = /%{(.*?)}%/gs

  function prepareMatchingInformationHTML(text: string) {
    // return the response in a string format with all input placeholders with keys
    return text.replace(MATCHING_CRITERIA_REGEX, (match) => {
      const matchText = match.replace(/%/g, '').replace(/{/g, '').replace(/}/g, '')
      const matchClass = slugify(matchText)

      return `<input class="${matchClass}" placeholder="${matchText}" />`
    })
  }

  const isSubmittingRef = ref(false)

  const { accountRef } = useAccount()
  const roleRef = computed(() => accountRef.value?.role)
  const toast = useToast()
  const { t } = useI18n()

  const matchingInformationRef = ref<string | null>(null)

  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
  const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)

  // get the matching information from the program
  watchEffect(() => {
    if (selectedProgramRef.value) {
      if (roleRef.value === RoleEnumType.MENTOR) {
        matchingInformationRef.value = selectedProgramRef.value.settings.mentorMatchingCriteria ?? null
      }
      if (roleRef.value === RoleEnumType.MENTEE) {
        matchingInformationRef.value = selectedProgramRef.value.settings.menteeMatchingCriteria ?? null
      }
    }
  })

  const onSubmit = (e: Event) => {
    e.preventDefault()
    if (!matchingInformationRef.value) return

    const matchToExtract = Array.from(matchingInformationRef.value.matchAll(MATCHING_CRITERIA_REGEX)).map((m) => m)

    // get all inputs values
    const fillValues: Record<string, string> = {}
    matchToExtract.forEach(([key, matchText]) => {
      fillValues[key] = document.querySelector<HTMLInputElement>(`.${slugify(matchText)}`)!.value ?? null
    })

    let filledCriteria = matchingInformationRef.value

    // replace all inputs placeholders with values
    Array.from(Object.entries(fillValues)).forEach(([key, value]) => {
      filledCriteria = filledCriteria.replace(key, value)
    })

    if (accountRef.value?.id && filledCriteria && selectedProgramIdRef.value) {
      onSubmitCallback(filledCriteria)
    } else {
      toast?.error(t('error.common'))
    }
  }

  return {
    prepareMatchingInformationHTML,
    matchingInformationRef,
    isSubmittingRef,
    onSubmit,
  }
}
