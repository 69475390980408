import type { RoleEnumType } from '@/v1.5/features/auth/types'
import type { V2UserDataType } from '@/v1.5/features/users/types'

export enum SessionStatusEnumType {
  PLANNED = 'PLANNED',
  WAIT_REVIEW = 'WAIT_REVIEWS',
  WAIT_CONFIRMATION = 'WAIT_CONFIRMATION',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  NONE = 'NONE', // none for admin who does not have a session
  ONGOING = 'ONGOING', // ongoing for admin view only
}

export type SessionLocationType = 'visio' | string

export type V1SessionType = {
  id: string
  at: string | null
  from: string
  to: string
  location: SessionLocationType
  duration: number
  mentoringGroupId: string
  createdAt: string
  updatedAt: string
  users: {
    role: RoleEnumType
    email: string
    id: string
    identity: {
      firstname: string
      lastname: string
    }
    personalInformation: {
      profilPicture: string
    }
  }[]
  mentorshipGroup: {
    conversationId: string
    createdAt: string
    id: string
    idByMentor: number
    meetingRoomId: string
    programId: string
    updatedAt: string
    users: {
      id: string
      email: string
      role: RoleEnumType
      identity: {
        firstname: string
        lastname: string
      }
      personalInformation: {
        profilPicture: string
      }
    }[]
  }
  presences: {
    userId: string
    isPresent: boolean
    user: {
      id: string
      email: string
      password: string | null
      tokenForgotPassword: string | null
      role: RoleEnumType
      createdAt: string
      updatedAt: string
      samlId: string | null
    }
  }[]
  deliverables: DeliverableType[]
  statuses: { status: SessionStatusEnumType; userId: string }[]
  report: {
    comment: string
    privateComment: string | null
    userId: string
  }
  reviews: {
    grade: number
    review: string
    userId: string
  }[]
}

export type V2SessionType = {
  id: string
  at: string | null
  from: string
  to: string
  location: SessionLocationType
  duration: number
  mentoringGroupId: string
  meetingRoomId: string
  createdAt: string
  updatedAt: string
  status: SessionStatusEnumType
  users: (V2UserDataType & {
    sessionStatus: SessionStatusEnumType
  })[]
  report: {
    comment: string
    privateComment: string | null
    userId: string
  } | null
  deliverables: DeliverableType[]
  reviews: {
    grade: number
    review: string
    userId: string
  }[]
  presences: {
    userId: string
    wasPresent: boolean
  }[]
}
export type GetSessionsDTOType = {
  programId: string
  periodId: string
  mentorshipGroupId: string
  page: number
  perPage: number
  filterEmail: string
}

export type GetSessionDTOType = {
  id: string
}

export type GetSessionsResponseType = {
  sessions: V1SessionType[]
  totalSessions: number
  totalPages: number
}

export type GetSessionResponseType = {
  session: V1SessionType
}

export type ReviewSessionDTOType = {
  sessionId: string
  rating?: number
  comment?: string
}

export type V1AdminExportSessionsDTOType = {
  programId: string
  periodId: string
}

export type V1AdminExportSessionsResponseType = {
  mentee1DelivrableRating: string
  mentee1DelivrableUrl: string
  mentee1Email: string
  mentee1FirstName: string
  mentee1LastName: string
  mentee1Presence: string
  mentee1ReviewComment: string
  mentee2DelivrableRating: string
  mentee2DelivrableUrl: string
  mentee2Email: string
  mentee2FirstName: string
  mentee2LastName: string
  mentee2Presence: string
  mentee2ReviewComment: string
  // menteeXDeliverable...
  // menteeXEmail...
  mentorDelivrableReport: string
  sessionDate: string
  mentorEmail: string
  mentorFirstName: string
  mentorLastName: string
  sessionNumber: string
  totalDeliverablesRating: string
}[]

export type V1CreateSessionDTOType = {
  userId: string
  from: string
  to: string
  mentoringGroupId: string
  location: string
}

export type V1CreateSessionResponseType = {
  message: string
  session: V1SessionType
}

export type V2SessionDetailsType = V2SessionType & object

export type V1UpdateSessionStatusDTOType = {
  sessionId: string
  status: SessionStatusEnumType
}

export type V1ReviewSessionDTOType = {
  userId: string
  grade: number
  review?: string
  sessionId: string
}

export type UploadDeliverableDTOType = {
  sessionId: string
  userId: string
  mimetype: string
}

export type DeliverableType = {
  createdAt: string
  file: string
  id: string
  isImported: boolean
  rating: number | null
  sessionId: string
  updatedAt: string
  url: string
  userId: string
}

export type UploadDeliverableResponseType = DeliverableType

export type GetAllDeliverablesDTOType = {
  sessionId: string
}

export type GetDeliverableByUserIdDTOType = {
  sessionId: string
  userId: string
}

export type GetDeliverableURLDTOType = {
  deliverableId: string
  sessionId: string
  userId: string
}

export type GetDeliverableURLResponseType = {
  url: string
}

export type CheckDeliverableImportationDTOType = {
  deliverableId: string
}

export type CheckDeliverableImportationResponseType = {
  delivrableIsImported: boolean
}

export type DeleteDeliverableDTOType = {
  deliverableId: string
  sessionId: string
  userId: string
}

export type V1AddDeliverableRateDTOType = {
  userId: string
  sessionId: string
  rate: number
}

export enum DeliverablesNotationPossibleStatusType {
  RATED = 'RATED',
  NOT_RATED = 'NOT_RATED',
}

export type V1ConfirmPresenceDTOType = {
  sessionId: string
  presences: {
    userId: string
    isPresent: boolean
  }[]
}

export type V1AddSessionReportDTOType = {
  sessionId: string
  comment: string
}
