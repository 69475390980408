import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { computed } from 'vue'

import { getGroups, GROUPS_QUERY_KEY } from '@/v1.5/features/groups/api'
import type { GetGroupsResponseType, V2GroupType } from '@/v1.5/features/groups/types'
import { formGroupName } from '@/v1.5/features/groups/utils'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import { MAX_GROUPS_PER_PAGE } from '@/v1.5/utils/config/constants'

function V1ToV2DataMiddleware({ data }: { data: GetGroupsResponseType | undefined }): V2GroupType[] {
  const groups = data?.groups?.map((group) => {
    return {
      id: group.id,
      idByMentor: group.idByMentor,
      conversationId: group.conversationId,
      meetingRoomId: group.meetingRoomId,
      programId: group.programId,
      name: formGroupName(group),
      users: group.users.map((user) => ({
        id: user.id,
        firstName: user.identity?.firstname,
        lastName: user.identity?.lastname,
        picture: user.personalInformation?.profilPicture,
        email: user.email,
        role: user.role,
      })),
      completedSessions: group.sessions.length,
    } satisfies V2GroupType
  })

  return groups || []
}

const useGroups = ({
  pageRef,
  perPageRef,
  searchRef,
}: {
  pageRef?: Ref<number>
  perPageRef?: Ref<number>
  searchRef?: Ref<string | null>
} = {}) => {
  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

  const getUsersPageRef = computed(() => pageRef?.value || 1)
  const getUsersPerPageRef = computed(() => perPageRef?.value || MAX_GROUPS_PER_PAGE)
  const getUsersSearchRef = computed(() => searchRef?.value || '')

  // Check if all the required data is available to make the query
  const isGroupsQueryEnabledRef = computed(() => !!selectedProgramIdRef.value)

  const groupsQuery = useQuery({
    queryKey: [
      GROUPS_QUERY_KEY,
      {
        programId: selectedProgramIdRef,
        search: getUsersSearchRef,
        perPage: getUsersPerPageRef,
        page: getUsersPageRef,
      },
    ],
    queryFn: () =>
      getGroups({
        programId: selectedProgramIdRef.value!,
        page: getUsersPageRef.value!,
        search: getUsersSearchRef.value,
        perPage: getUsersPerPageRef.value,
      }),
    enabled: isGroupsQueryEnabledRef,
  })

  const data = computed(() => {
    return V1ToV2DataMiddleware({
      data: groupsQuery.data.value,
    })
  })

  const totalPagesRef = computed(() => groupsQuery.data.value?.totalPages)
  const totalGroupsRef = computed(() => groupsQuery.data.value?.totalGroups)

  return {
    groupsRef: data,
    totalPagesRef,
    totalGroupsRef,
    isPendingRef: groupsQuery.isPending,
  }
}

export default useGroups
