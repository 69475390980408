<template>
  <ModalStep
    id="rate-session-modal-content"
    :header="{
      title: t('session.addReviewModal.title'),
    }"
  >
    <template #bodyContent>
      <div class="c-info-wrapper">
        <p class="c-info-label">
          <strong>
            {{ t('session.addReviewModal.body') }}
          </strong>
        </p>
        <p className="c-info-text">
          {{
            accountRef?.role === RoleEnumType.MENTOR
              ? t('session.addReviewModal.infoMenteesNoAccess')
              : t('session.addReviewModal.infoMentorNoAccess')
          }}
        </p>
      </div>
      <CustomTextarea
        :label="`${t('session.addReviewModal.input.review.label')} (${t('ui.input.label.optional')})`"
        id="review-session"
        :placeholder="`${t('session.addReviewModal.input.review.placeholder')}`"
        :value="reviewRef"
        @emit:change="onChange"
      />
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="submit"
          color="white"
          :text="t('ui.button.fillLater')"
          @emit:click="onCloseAndFillLater"
        />
        <CustomButton
          :is-loading="isReviewSessionPendingRef"
          usage="button"
          type="submit"
          color="primary"
          :text="`${t('ui.button.next')}`"
          icon-position="right"
          @emit:click="onSubmit"
        >
          <template #icon>
            <ArrowIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { reviewSessionMutation } from '@/v1.5/features/sessions/api'
import { useSessionInReview } from '@/v1.5/features/sessions/hooks/use-in-review-session.hook'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import CustomTextarea from '@/v1.5/features/ui/components/custom-textarea/custom-textarea.vue'
import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetReviewSessionModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { DO_NOT_ASK_REVIEW_AGAIN } from '@/v1.5/utils/config/constants'
import { invalidateSession, invalidateSessions } from '@/v1.5/utils/lib/vue-query'

const reviewRef = ref<string | undefined>(undefined)
const toast = useToast()
const { t } = useI18n()

const { accountRef } = useAccount()

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const reviewSessionModalRef = selectGetReviewSessionModal(modalsStore)

const { onClickNextStep } = useSessionInReview()

function onChange(text: string) {
  reviewRef.value = text
}

// we close the modal and for this session, we won't ask for a review again
function onCloseAndFillLater() {
  sessionStorage.setItem(DO_NOT_ASK_REVIEW_AGAIN, 'true')
  modalsStore.toggleModal('review-session', false)
}

const { mutate: reviewSession, isPending: isReviewSessionPendingRef } = useMutation({
  mutationFn: reviewSessionMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    // on success, we invalidate the session
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateSession(reviewSessionModalRef.value.attachedData.sessionId!)

    toast?.success(t('session.addReviewModal.success'))

    onClickNextStep()
  },
})

function onSubmit() {
  try {
    // we send the mutation to review the session
    reviewSession({
      comment: reviewRef.value ?? '',
      sessionId: reviewSessionModalRef.value.attachedData.sessionId!,
    })
  } catch (error) {
    console.error(error)
    toast?.error(t('error.common'))
  }
}
</script>
