<template>
  <div :class="`input-wrapper ${id} ${hasErrors ? 'has-errors' : ''}`">
    <!-- input label -->
    <label v-if="label" class="input-label" :for="id">
      <span>{{ label }}</span>
    </label>
    <!-- input element -->
    <div class="input-el-wrapper">
      <textarea
        :disabled="isDisabled"
        class="input"
        v-model="valueRef"
        :data-value="valueRef"
        :placeholder="placeholder"
        @input="onChange"
        @keyup="onKeyUp"
      ></textarea>
    </div>
    <div v-if="errors" class="input-errors-wrapper">{{ errors }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import debounce from '@/v1.5/utils/misc/debounce'

interface InputPropsType {
  id: string
  errors?: string
  label?: string
  placeholder: string
  value?: string | null
  isDisabled?: boolean
  onChangeDebounceDelay?: number
}

const props = defineProps<InputPropsType>()

// set default value v-model
const valueRef = ref(props.value || '')

defineExpose({
  clear() {
    valueRef.value = ''
  },
  set(message: string) {
    valueRef.value = message
  },
})

const emit = defineEmits(['emit:change', 'emit:onkeyup'])

const onChange = debounce(
  (event: Event) => {
    const value = (event.target as HTMLInputElement).value
    // Emit the new value to the parent with the event name 'update:modelValue'
    emit('emit:change', value)
  },
  props.onChangeDebounceDelay || 500,
  false,
)

const onKeyUp = (event: KeyboardEvent) => {
  emit('emit:onkeyup', event)
}

// Computed properties for conditional logic
const hasErrors = computed(() => !!props.errors)
</script>

<style scoped lang="scss">
@import './custom-textarea.scss';
</style>

<!-- import './textarea.scss'

import React, { forwardRef } from 'react'

interface TextAreaPropsType extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string
  errors: string | undefined
  label: string | React.ReactNode
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaPropsType>(({ ...otherProps }, ref) => {
  const { ...textAreaProps } = otherProps
  const hasErrors = textAreaProps.errors ? true : false

  return (
    <div className={`input-wrapper ${hasErrors ? 'has-errors' : ''}`}>
      {/* input label */}
      {textAreaProps.label && (
        <label className="input-label" htmlFor={textAreaProps.id}>
          <span>{textAreaProps.label}</span>
        </label>
      )}
      {/* input element */}
      <div className="input-el-wrapper">
        <textarea ref={ref} className="input" {...textAreaProps} />
      </div>

      {textAreaProps.errors && <div className="input-errors-wrapper">{textAreaProps.errors}</div>}
    </div>
  )
})

TextArea.displayName = 'TextArea'

export default TextArea -->
