<template>
  <ModalStep
    id="rate-session-modal-content"
    :header="{
      title: t('session.rateSessionModal.title'),
    }"
  >
    <template #bodyContent>
      <div class="c-info-wrapper">
        <p class="c-info-label" style="text-align: center">
          <strong>{{ t('session.rateSessionModal.body') }}</strong>
        </p>
      </div>
      <SessionCard v-if="sessionRef" :session="sessionRef" />
      <div class="stars-container">
        <div :class="`star ${ratingRef >= 1 ? 'activated' : ''}`" @click="setRating(1)">
          <StarIcon color="black" />
        </div>
        <div :class="`star ${ratingRef >= 2 ? 'activated' : ''}`" @click="setRating(2)">
          <StarIcon color="black" />
        </div>
        <div :class="`star ${ratingRef >= 3 ? 'activated' : ''}`" @click="setRating(3)">
          <StarIcon color="black" />
        </div>
        <div :class="`star ${ratingRef >= 4 ? 'activated' : ''}`" @click="setRating(4)">
          <StarIcon color="black" />
        </div>
        <div :class="`star ${ratingRef >= 5 ? 'activated' : ''}`" @click="setRating(5)">
          <StarIcon color="black" />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="submit"
          color="white"
          :text="t('ui.button.fillLater')"
          @emit:click="onCloseAndFillLater"
        />
        <CustomButton
          :is-loading="isReviewSessionPendingRef"
          usage="button"
          type="submit"
          color="primary"
          :text="t('ui.button.continue')"
          icon-position="right"
          :is-disabled="ratingRef === 0"
          @emit:click="onSubmit"
        >
          <template #icon>
            <ArrowIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref } from 'vue'

import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { reviewSessionMutation } from '@/v1.5/features/sessions/api'
import SessionCard from '@/v1.5/features/sessions/components/session-card/session-card.vue'
import { useSessionInReview } from '@/v1.5/features/sessions/hooks/use-in-review-session.hook'
import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
import StarIcon from '@/v1.5/features/ui/components/icons/star-icon.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetReviewSessionModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import type { ReviewSessionModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { DO_NOT_ASK_REVIEW_AGAIN } from '@/v1.5/utils/config/constants'
import { invalidateSession, invalidateSessions } from '@/v1.5/utils/lib/vue-query'

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const ratingRef = ref<number>(0)
const reviewSessionModalRef = selectGetReviewSessionModal(modalsStore)

const sessionToReviewIdRef = computed(() => reviewSessionModalRef.value.attachedData.sessionId ?? null)
const { sessionRef } = useSession(sessionToReviewIdRef)

const toast = useToast()
const { t } = useI18n()

const { onClickNextStep } = useSessionInReview()

// we set the rating inside the temp attached modal data
function setRating(number: number) {
  ratingRef.value = number
  modalsStore.updateModalAttachedData<ReviewSessionModalType>('review-session', {
    ...reviewSessionModalRef.value.attachedData,
    rating: number,
  })
}

const { mutate: reviewSession, isPending: isReviewSessionPendingRef } = useMutation({
  mutationFn: reviewSessionMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    // on success, we invalidate the session
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateSession(sessionToReviewIdRef.value!)
    toast?.success(t('session.addReviewModal.success'))

    // we go to the next step
    onClickNextStep()
  },
})

// we close the modal and for this session, we won't ask for a review again
function onCloseAndFillLater() {
  sessionStorage.setItem(DO_NOT_ASK_REVIEW_AGAIN, 'true')
  modalsStore.toggleModal('review-session', false)
}

// submiting the note
function onSubmit() {
  reviewSession({
    sessionId: sessionToReviewIdRef.value!,
    rating: ratingRef.value,
  })
}
</script>

<style lang="scss">
@import './rate-session-modal-content.scss';
</style>
