import { create } from 'zustand'
import { combine, persist } from 'zustand/middleware'

import subscribeToStore from '@/v1.5/lib/zustand'

export type MenuStoreType = {
  isMobileMenuOpen: boolean
  isDesktopNavCollapsed: boolean
}

export const INITIAL_MENU_STORE_VALUE: MenuStoreType = {
  isMobileMenuOpen: false,
  isDesktopNavCollapsed: false,
}

let collapseTimeout: ReturnType<typeof setTimeout> | null = null

const MENU_STORAGE_KEY = 'menu'
const menuStore = subscribeToStore(
  create(
    persist(
      combine(INITIAL_MENU_STORE_VALUE, (set, get) => ({
        toggleMobileMenu: () => {
          const isOpen = get().isMobileMenuOpen
          set({ isMobileMenuOpen: !isOpen })
        },
        toggleDesktopNavCollapsed: () => {
          const isCollapsed = get().isDesktopNavCollapsed

          if (collapseTimeout) {
            clearTimeout(collapseTimeout)
          }
          const layout = document.querySelector<HTMLDivElement>('.main-layout')!
          layout.classList.add('js-nav-collapsed--is-transitioning')
          layout.classList.toggle('js-nav-collapsed')
          set({ isDesktopNavCollapsed: !isCollapsed })

          collapseTimeout = setTimeout(() => {
            layout.classList.remove('js-nav-collapsed--is-transitioning')
          }, 500)
        },
      })),
      {
        name: MENU_STORAGE_KEY,
      },
    ),
  ),
)

export default menuStore

// import { setAuthTokenAction } from '@/v1.5/features/auth/stores/auth/auth.actions'
// import { selectGetAuthToken } from '@/v1.5/features/auth/stores/auth/auth.selectors'

// export type AuthModuleStateType = {
//   authToken: string | null
// }

// const authModule = {
//   namespaced: true as const,
//   state: {
//     authToken: localStorage.getItem('token'),
//   } as AuthModuleStateType,
//   getters: {
//     authToken: selectGetAuthToken,
//   },
//   actions: {
//     setAuthToken: setAuthTokenAction,
//   },
//   mutations: {
//     SET_AUTH_TOKEN: (state: AuthModuleStateType, authToken: AuthModuleStateType['authToken']) => {
//       state.authToken = authToken
//     },
//   },
// }

// export default authModule
