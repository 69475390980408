<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <Page>
    <template v-slot:middle>
<Sessions/>
    </template>
    <template v-slot:outside>
      <OldSessionDetailsPopup/>

    </template>
  </Page>
</template>

<script>
import OldSessionDetailsPopup from '@/components/admin/sessions/SessionDetailsPopup.vue'
import Page from '@/components/Page.vue'
import Sessions from '@/v1.5/(routes)/(protected)/(app)/admin/sessions/page.vue'

export default {
  name: 'AdminSessions',
  data() {
    return {}
  },
  components: {
    Page,
    Sessions,
    OldSessionDetailsPopup,
  },
  computed: {},
  methods: {},
}
</script>
