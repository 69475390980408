// @ts-nocheck 
// eslint-disable
import axios from 'axios'

const apiURL = import.meta.env.VITE_API_URL
const instance = axios.create({
  baseURL: `${apiURL}/v1/auth`,
})

// POST "/login"
export async function login(email, password) {
  const res = await instance
    .post('/login', {
      email: email,
      password: password,
    })
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}

// POST "/register"
export async function register(data, params = null) {
  // const newData = {
  //   email: data.email,
  //   password: data.password,
  //   confirmPassword: data.confirmPassword,
  //   cguAccepted: data.cguAccepted,
  //   grade: data.grade,
  //   firstName: data.firstName,
  //   lastName: data.lastName,
  //   phoneNumber: data.phoneNumber
  // }
  const res = await instance
    .post(`/register${params ? params : ''}`, data)
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}

export async function forgotPassword(email) {
  const res = await instance
    .post('/forgotPassword', {
      email: email,
    })
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}

export async function updateForgotPassword(data) {
  const res = await instance
    .post('/updateForgotPassword', {
      token: data.token,
      password: data.password,
      confirmPassword: data.confirmPassword,
    })
    .catch(function (error) {
      if (error.response) {
        return error.response
      }
    })
  return res.data
}

export async function apiAuthGetUserData(authToken) {
  const req = await fetch(`${import.meta.env.VITE_API_URL}/v1/auth/user`, {
    method: 'GET',
    headers: { authorization: `Bearer ${authToken}` },
  })
  return await req.json()
}
