<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <PageOnboarding>
    <template v-slot:right>
      <div class="onboarding__body__form">
        <h1 class="fs-5 fw-normal mb-4 text-center">
          Connexion à votre espace <br />
          {{ $store.getters.appTitle }}
        </h1>
        <p class="fs-6 text-center">
          Si vous faites partie de {{ $store.getters.appTitle }}, utilisez la
          connexion rapide, sinon, veuillez utiliser une connexion classique
          avec email et mot de passe.
        </p>

        <div class="btns-container">
          <router-link
            :to="{
              name: 'SAML',
            }"
            class="btn btn-primary"
          >
            Connexion rapide avec {{ $store.getters.appTitle }}
          </router-link>
          <router-link
            :to="{ name: 'EmailLogin' }"
            class="btn btn-outline-primary"
          >
            Se connecter avec email et mot de passe
          </router-link>
        </div>
      </div>
    </template>
  </PageOnboarding>
</template>

<script>
import PageOnboarding from '@/components/PageOnboarding.vue'
import { SetLoginData } from '@/mixines/SetLoginData'

export default {
  name: 'Login',
  data() {
    return {
      preselectMentor: this.$route.params.type == 'student' ? false : true,
    }
  },
  mounted() {
    if (this.$store.getters.authSaml) {
      this.$router.push({ name: 'SAML' }).catch(() => {})
    }
  },
  components: {
    PageOnboarding,
  },
  mixins: [SetLoginData],
  methods: {},
}
</script>

<style lang="scss" scoped>
.onboarding {
  &__head {
    &__text {
      max-width: 25rem;
    }
  }
  &__body {
    &__form {
      width: 25rem;
      @media all and (max-width: 425px) {
        width: 300px;
      }

      .btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;
        > * {
          width: 100%;
        }
      }
    }
  }
}
</style>
