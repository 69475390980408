// @ts-nocheck
// eslint-disable
// const { getAppData } = require('@/services/DataService.js')
import { getAppData } from '@/services/DataService.js'

const state = {
  supportPhone: null,
  supportEmail: null,
  indiceMentor: null,
  logo: null,
  favicon: null,
  police: null,
  policeUrl: null,
  customStyle: null,
  appTitle: null,
  colorPrimary: null,
  colorSecondary: null,
  authSaml: false,
  authSamlIdp: null,
  userCanSwitchVisibility: false,
  matching: false,
  maintenance: null,
  appCompanyName: '',
  blockRegistration: false
}

const getters = {
  supportPhone: (state) => state.supportPhone,
  supportEmail: (state) => state.supportEmail,
  indiceMentor: (state) => state.indiceMentor,
  logo: (state) => state.logo,
  favicon: (state) => state.favicon,
  police: (state) => state.police,
  policeUrl: (state) => state.policeUrl,
  customStyle: (state) => state.customStyle,
  appTitle: (state) => state.appTitle,
  colorPrimary: (state) => state.colorPrimary,
  colorSecondary: (state) => state.colorSecondary,
  authSaml: (state) => state.authSaml,
  authSamlIdp: (state) => state.authSamlIdp,
  userCanSwitchVisibility: (state) => state.userCanSwitchVisibility,
  matching: (state) => state.matching,
  maintenance: (state) => state.maintenance,
  appCompanyName: (state) => state.appCompanyName,
  blockRegistration: (state) => state.blockRegistration,
  mentorReportTemplate: (state) => state.mentorReportTemplate
}

const actions = {
  async fetchAppData({ commit }) {
    const appData = await getAppData(this.getters.authToken)
    for (let i = 0; i < appData.setting.length; i++) {
      if (appData.setting[i].name === 'SUPPORT_PHONE') {
        commit('SET_SUPPORT_PHONE', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'SUPPORT_EMAIL') {
        commit('SET_SUPPORT_EMAIL', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'INDICE_MENTOR') {
        commit('SET_INDICE_MENTOR', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'LOGO') {
        commit('SET_LOGO', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'FAVICON') {
        commit('SET_FAVICON', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'CUSTOM_STYLE') {
        commit('SET_CUSTOM_STYLE', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'TITLE') {
        commit('SET_APP_TITLE', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'COLOR_PRIMARY') {
        commit('SET_COLOR_PRIMARY', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'COLOR_SECONDARY') {
        commit('SET_COLOR_SECONDARY', appData.setting[i].value)
      }

      if (appData.setting[i].name === 'AUTH_SAML') {
        commit('AUTH_SAML', appData.setting[i].value == 'true')
      }
      if (appData.setting[i].name === 'COMPANY_NAME') {
        commit('SET_APP_COMPANY_NAME', appData.setting[i].value)
      }
      if (appData.setting[i].name === 'BLOCK_REGISTRATION') {
        commit('SET_BLOCK_REGISTRATION', appData.setting[i].value)
      }
    }
  }
}

const mutations = {
  SET_SUPPORT_PHONE(state, supportPhone) {
    state.supportPhone = supportPhone
  },
  SET_SUPPORT_EMAIL(state, supportEmail) {
    state.supportEmail = supportEmail
  },
  SET_INDICE_MENTOR(state, indiceMentor) {
    state.indiceMentor = indiceMentor
  },
  SET_LOGO(state, logo) {
    state.logo = logo
  },
  SET_MATCHING(state, matching) {
    state.matching = matching
  },
  SET_FAVICON(state, favicon) {
    state.favicon = favicon
  },
  SET_POLICE(state, police) {
    state.police = police
  },
  SET_POLICE_URL(state, policeUrl) {
    state.policeUrl = policeUrl
  },
  SET_CUSTOM_STYLE(state, customStyle) {
    state.customStyle = customStyle
  },
  SET_APP_TITLE(state, appTitle) {
    state.appTitle = appTitle
  },
  SET_COLOR_PRIMARY(state, colorPrimary) {
    state.colorPrimary = colorPrimary
  },
  SET_COLOR_SECONDARY(state, colorSecondary) {
    state.colorSecondary = colorSecondary
  },
  AUTH_SAML(state, authSaml) {
    state.authSaml = authSaml
  },
  SET_AUTH_SAML_IDP(state, authSamlIdp) {
    state.authSamlIdp = authSamlIdp
  },
  SET_MAINTENANCE(state, maintenance) {
    state.maintenance = maintenance
  },
  SET_APP_COMPANY_NAME(state, appCompanyName) {
    state.appCompanyName = appCompanyName
  },
  SET_BLOCK_REGISTRATION(state, blockRegistration) {
    state.blockRegistration = blockRegistration
  }
}

export const moduleApp = {
  state,
  getters,
  actions,
  mutations
}
