import posthog from 'posthog-js'

import type { V2AccountDataType } from '@/v1.5/features/auth/types'

export const PosthogClient = posthog

export const EVENTS = {
  userCompletedSessionReview: 'user_completed_session_review', // when the user finish all mandatory step of the session review and click on submit
}

export function initPosthog() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  const apiKey = import.meta.env.VITE_POSTHOG_API_KEY as string
  if (!apiKey) {
    return
  }
  // posthog only works when the key is set (could be a dev or prod key)
  PosthogClient.init(apiKey, {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
  })
}

export function identifyUser(account: Partial<V2AccountDataType>) {
  PosthogClient.identify(account.id)
}
