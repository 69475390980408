<!-- eslint-disable -->
<!-- @ts-nocheck -->
<template>
  <PageOnboarding>
    <template v-slot:right>
      <div
        v-if="displaySendEmail"
        class="d-flex flex-column align-items-center"
      >
        <i class="far fa-paper-plane fs-2 mb-2"></i>
        <p>
          Un email permettant de redéfinir votre mot de passe vient de vous être
          envoyé
        </p>
      </div>
      <form
        v-if="!token && !displaySendEmail"
        @input="formIsValid"
        @submit.prevent="submitForm"
      >
        <h1 class="fs-4">
          {{
            activate
              ? 'Redéfinissez votre mot de passe pour garder votre compte'
              : 'Récuperation du mot de passe'
          }}
        </h1>
        <p v-if="!activate">
          Une fois l'email renseigné nous vous enverrons un mail qui vous
          permettra de re définir votre mot de passe
        </p>
        <p v-if="activate">
          Une fois votre email renseigné, vous recevrez un mail pour choisir
          votre nouveau mot de passe
        </p>
        <label for="email" class="form-label">Email</label>
        <input
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          v-model="email"
          type="email"
          placeholder="votre adresse email"
          id="email"
          required
          class="form-control fgpassword"
        />
        <div class="d-grid">
          <button
            type="submit"
            :disabled="!isValid"
            class="btn btn-primary mt-3"
          >
            {{ activate ? 'Redéfinir' : 'Récuperer' }}
          </button>
        </div>
        <div class="d-flex justify-content-end">
          <router-link
            class="text-decoration-none mt-2"
            :to="{ name: 'Login' }"
          >
            <i class="fas fa-long-arrow-alt-left"></i>
            Retour
          </router-link>
        </div>
      </form>
      <form
        @input="formIsValid"
        v-if="token"
        @submit.prevent="submitNewPassword"
      >
        <h1 class="fs-4">Redéfinissez votre mot de passe</h1>
        <p>
          Créez un mot de passe fort qui contient au minimum une
          <strong>minuscule</strong>
          , une
          <strong>majuscule</strong>
          et un
          <strong>chiffre</strong>
        </p>
        <div class="input-group has-validation mb-2">
          <input
            ref="password"
            :class="{ 'is-invalid': passwordError }"
            class="form-control fgpassword"
            type="password"
            placeholder="Créez un mot de passe"
            v-model="password"
            autocomplete="new-password"
            id="password"
            @focusout="PasswordMatchError"
          />
          <div class="invalid-feedback">{{ passwordError }}</div>
        </div>
        <div id="emailHelp" class="form-text"></div>
        <label for="confirm-password" class="form-label mt-3"
          >Saisissez à nouveau votre mot de passe</label
        >
        <div class="input-group has-validation">
          <input
            required
            @input="verifyPasswordMatch"
            @focusout="PasswordMatchError"
            :class="{ 'is-invalid': confirmPasswordError }"
            class="form-control fgpassword"
            type="password"
            placeholder="Confirmez votre mot de passe"
            v-model="confirmPassword"
            autocomplete="new-password"
            id="confirm-password"
          />
          <div class="invalid-feedback">
            {{ confirmPasswordError }}
          </div>
        </div>
        <div class="d-grid">
          <button
            :disabled="!isValid"
            type="submit"
            class="btn btn-primary mt-3"
          >
            Changer
          </button>
        </div>
      </form>
    </template>
  </PageOnboarding>
</template>

<script>
import PageOnboarding from '@/components/PageOnboarding.vue'
import {
  forgotPassword,
  updateForgotPassword,
  login,
} from '@/services/AuthService.js'
import { SetLoginData } from '@/mixines/SetLoginData'

export default {
  name: 'forgotPassword',
  components: {
    PageOnboarding,
  },
  data() {
    return {
      email: null,
      isValid: false,
      token: null,
      password: null,
      passwordError: '',
      confirmPassword: null,
      confirmPasswordError: '',
      passwordMatch: false,
      displaySendEmail: false,
      activate: false,
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.action == 'activate') {
      this.activate = true
    }
    if (this.$route.params) {
      this.token = this.$route.params.token
    }
  },
  mixins: [SetLoginData],
  methods: {
    formIsValid() {
      const inputs = document.querySelectorAll('.fgpassword')
      let isValid = true
      inputs.forEach((input) => {
        if (!input.checkValidity()) isValid = false
      })
      if (this.token) {
        if (isValid && this.passwordMatch) return (this.isValid = true)
        return (this.isValid = false)
      } else {
        if (isValid) return (this.isValid = true)
        return (this.isValid = false)
      }
    },
    PasswordMatchError() {
      if (!this.verifyPasswordMatch() && this.confirmPassword)
        this.confirmPasswordError =
          'Les mots de passe ne correspondent pas. Veuillez vérifier leur orthographe.'
      else this.confirmPasswordError = ''
    },
    verifyPasswordMatch() {
      if (this.password && this.password == this.confirmPassword) {
        this.passwordMatch = true
        return true
      } else {
        this.passwordMatch = false
        return false
      }
    },
    async submitForm() {
      await forgotPassword(this.email)
      this.displaySendEmail = true
    },
    async submitNewPassword() {
      let req = await updateForgotPassword({
        token: this.$route.params.token,
        password: this.password,
        confirmPassword: this.confirmPassword,
      })
      // connect l'utilisateur
      req = await login(req.email, this.password)
      if (!req.error) {
        localStorage.setItem('userId', req.userId)
        localStorage.setItem('token', req.token)
        this.$store.dispatch('authUpdateToken', req.token)
        this.startSetLoginData()
        this.$store.commit('SET_AUTH_USER_ID', req.userId)
        this.$router.push({
          name: 'IndexProfile',
          params: { id: req.userId },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
form {
  max-width: 400px;
}

.progress {
  height: 5px !important;
}
strong {
  color: var(--color-primary);
}
</style>
