import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'

import { DELIVERABLES_QUERY_KEY, getAllDeliverables } from '@/v1.5/features/sessions/api'

export default function useDeliverables(sessionId: ComputedRef<string | null | undefined>) {
  const isGetDeliverablesQueryEnabled = computed(() => !!sessionId.value)

  const { data, isPending } = useQuery({
    queryKey: [DELIVERABLES_QUERY_KEY, { sessionId: sessionId }],
    queryFn: () => getAllDeliverables({ sessionId: sessionId.value! }),
    enabled: isGetDeliverablesQueryEnabled,
  })

  return {
    deliverablesRef: data,
    isPendingRef: isPending,
  }
}
