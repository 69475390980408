import EditorJS from '@editorjs/editorjs'
import type { ComputedRef } from 'vue'
import { computed, ref, watch } from 'vue'

import useUser from '@/v1.5/features/users/hooks/use-user.hook'
import { editorJSI18n, editorJSTools } from '@/v1.5/lib/editorjs'

export default function useProfileDescription(userId: ComputedRef<string | undefined>, holderClassName: string) {
  const { userRef } = useUser(userId)

  const editorRef = ref<EditorJS | null>(null)
  const descriptionRef = ref<any>(null)
  const hasDescriptionRef = computed<boolean>(() => {
    return descriptionRef.value?.blocks.length > 0
  })

  watch(
    userRef,
    (user) => {
      if (user) {
        descriptionRef.value = JSON.parse(user.description ?? '{"blocks": []}') as { blocks: any[] }
      }
    },
    { immediate: true },
  )

  watch(
    [descriptionRef, hasDescriptionRef],
    ([description, hasDescription]) => {
      // destroy the editor if it exists
      descriptionRef.value.innerHTML = ''
      if (editorRef.value) {
        editorRef.value.destroy()
      }

      // if there is no description, return
      if (!hasDescription) return

      // create the editor
      editorRef.value = new EditorJS({
        holder: holderClassName,
        readOnly: true,
        data: hasDescription ? description : undefined,
        i18n: editorJSI18n,
        tools: editorJSTools,
      })
    },
    { immediate: true },
  )

  return {
    hasDescriptionRef,
  }
}
