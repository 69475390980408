<template>
  <ModalStep
    id="presence-session-modal-content"
    :header="{
      title: t('session.presencesModal.title'),
    }"
  >
    <template #bodyContent>
      <div class="c-info-wrapper">
        <p class="c-info-label" style="text-align: center">
          <strong>{{ t('session.presencesModal.body') }}</strong>
        </p>
      </div>
      <div class="presence-listing">
        <div v-for="mentee in menteesRef" :key="mentee.id" class="presence-card">
          <UserInfo :user="mentee" :sub-info="mentee.role === RoleEnumType.MENTEE ? 'Mentoré' : 'Mentor'" />
          <StatusBadge
            :status="
              menteesPresencesRef.find((presence) => presence.id === mentee.id)?.wasPresent
                ? SessionStatusEnumType.FINISHED
                : SessionStatusEnumType.CANCELLED
            "
            type="user"
          />
          <CustomSwitch
            :checked="!!menteesPresencesRef.find((presence) => presence.id === mentee.id)?.wasPresent"
            @emit:click="togglePresence(mentee.id)"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="submit"
          color="white"
          :text="t('ui.button.fillLater')"
          @emit:click="onCloseAndFillLater"
        />
        <CustomButton
          :is-loading="isConfirmPresencesPendingRef"
          usage="button"
          type="submit"
          color="primary"
          :text="t('ui.button.continue')"
          icon-position="right"
          @emit:click="onFinish"
        >
          <template #icon>
            <ArrowIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'

import { RoleEnumType } from '@/v1.5/features/auth/types'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import { getMentees, getMentor } from '@/v1.5/features/groups/utils'
import { V1ConfirmPresencesMutation } from '@/v1.5/features/sessions/api'
import { useSessionInReview } from '@/v1.5/features/sessions/hooks/use-in-review-session.hook'
import useSession from '@/v1.5/features/sessions/hooks/use-session.hook'
import { SessionStatusEnumType } from '@/v1.5/features/sessions/types'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import StatusBadge from '@/v1.5/features/ui/components/status-badge/status-badge.vue'
import CustomSwitch from '@/v1.5/features/ui/components/switch/custom-switch.vue'
import { selectGetReviewSessionModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import UserInfo from '@/v1.5/features/users/components/user-info/user-info.vue'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { invalidateSession, invalidateSessions } from '@/v1.5/utils/lib/vue-query'

const toast = useToast()
const { t } = useI18n()

const reviewSessionModalRef = selectGetReviewSessionModal(modalsStore)
const selectedSessionInReviewIdRef = computed(() => reviewSessionModalRef.value.attachedData.sessionId ?? null)
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { sessionRef } = useSession(selectedSessionInReviewIdRef)
const menteesRef = computed(() => sessionRef.value?.users.filter(getMentees) ?? [])
const mentorRef = computed(() => sessionRef.value?.users.find(getMentor))

const { onClickNextStep } = useSessionInReview()

let menteesPresencesRef = ref<{ id: string; wasPresent: boolean }[]>([])
let mentorPresenceRef = ref<{ id: string; wasPresent: boolean } | null>(null)

// initialize mentees presences with 'true' for all mentees
watch(
  menteesRef,
  (mentees) => {
    menteesPresencesRef.value = mentees.map((mentee) => {
      return {
        id: mentee.id,
        wasPresent: true,
      }
    })
  },
  { immediate: true },
)

// initialize the mentor presence with 'true'
watch(
  mentorRef,
  (mentor) => {
    if (!mentor) return
    mentorPresenceRef.value = {
      id: mentor.id,
      wasPresent: true,
    }
  },
  { immediate: true },
)

// toggle presence of a mentee
function togglePresence(menteeId: string) {
  const menteePresence = menteesPresencesRef.value.find((presence) => presence.id === menteeId)!
  menteePresence.wasPresent = !menteePresence.wasPresent
  menteesPresencesRef.value = [...menteesPresencesRef.value]
}

const { mutate: confirmPresences, isPending: isConfirmPresencesPendingRef } = useMutation({
  mutationFn: V1ConfirmPresencesMutation,
  onSuccess: async () => {
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateSession(reviewSessionModalRef.value.attachedData.sessionId!)
    toast?.success(t('session.presencesModal.success'))

    onClickNextStep()
  },
  onError() {
    toast?.error(t('error.common'))
  },
})

function onFinish() {
  if (!mentorPresenceRef.value) {
    throw new Error('Mentor presence is not defined')
  }

  const presences = [...menteesPresencesRef.value, mentorPresenceRef.value]

  confirmPresences({
    sessionId: selectedSessionInReviewIdRef.value!,
    presences: presences.map((presence) => ({
      userId: presence.id,
      isPresent: presence.wasPresent,
    })),
  })
}

function onCloseAndFillLater() {
  modalsStore.toggleModal('review-session', false)
}
</script>

<style lang="scss">
@import './presences-session-modal-content.scss';
</style>
