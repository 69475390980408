import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'

import { DELIVERABLE_QUERY_KEY, getDeliverableByUserId } from '@/v1.5/features/sessions/api'

export default function useDeliverable({
  userId,
  sessionId,
}: {
  userId: ComputedRef<string | undefined | null>
  sessionId: ComputedRef<string | undefined | null>
}) {
  const isGetDeliverableQueryEnabled = computed(() => !!userId.value && !!sessionId.value)

  const { data, isPending } = useQuery({
    queryKey: [DELIVERABLE_QUERY_KEY, { userId: userId, sessionId: sessionId }],
    queryFn: () => getDeliverableByUserId({ userId: userId.value!, sessionId: sessionId.value! }),
    enabled: isGetDeliverableQueryEnabled,
  })

  return {
    deliverableRef: data,
    isPendingRef: isPending,
  }
}
