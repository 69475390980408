<template>
  <ModalStep
    id="add-deliverable-modal-content"
    :header="{
      title: t('session.reviewModal.addDeliverableModal.title'),
    }"
  >
    <template #bodyContent>
      <div class="c-info-wrapper">
        <p class="c-info-label">
          <strong>
            {{ t('session.reviewModal.addDeliverableModal.body') }}
          </strong>
        </p>
      </div>
      <LoadingContent v-if="isAlreadyUploadedDeliverablePendingRef" />
      <div
        v-else-if="deliverableRef || fileRef"
        :class="`file-uploading-container ${isFileUploadingRef ? '' : 'file-uploading-container--valid'}`"
      >
        <div class="file-uploading-container__information">
          <FileIcon color="primary" filled />
          <div class="deliverable-information">
            <p class="deliverable-information__name">{{ deliverableRef?.file || fileRef?.name }}</p>
            <p v-if="isFileUploadingRef" class="deliverable-information__sub-info">
              {{ uploadPercentageRef }}% {{ t('session.reviewModal.addDeliverableModal.sending') }}
            </p>
            <p v-else class="deliverable-information__sub-info">
              {{ t('sesion.reviewModal.addDeliverableModal.received') }}
            </p>
          </div>
        </div>

        <div class="file-uploading-container__status">
          <LoadingIcon color="primary" v-if="isFileUploadingRef" />
          <div class="deliverable-remove-btn" @click="removeDeliverableFile">
            <CancelIcon color="danger" />
          </div>
        </div>
      </div>
      <template v-else>
        <!-- <div v-if="!fileRef" class="no-deliverable-content">
          <CustomCheckbox
            id="no-deliverable-checkbox"
            label="Je n'ai pas de livrable à rendre."
            @emit:change="onNoDeliverableCheckboxChange"
          />
        </div> -->
        <FileUpload v-if="!isNoDeliverableRef" accept="" :callback="onDeliverableFileChange" />
      </template>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          :isDisabled="isFileUploadingRef || !!deliverableRef"
          usage="button"
          type="submit"
          color="white"
          :text="t('session.reviewModal.addDeliverableModal.sendLater')"
          @emit:click="onClose"
        />
        <CustomButton
          :isDisabled="!isNoDeliverableRef && (isFileUploadingRef || !fileRef)"
          usage="button"
          type="submit"
          color="primary"
          :text="t('session.reviewModal.addDeliverableModal.send')"
          @emit:click="onNext"
          icon-position="right"
        >
          <template #icon>
            <ArrowIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { selectGetSelectedGroupId } from '@/v1.5/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/v1.5/features/groups/stores/groups/groups.store'
import {
  checkDeliverableImportationMutation,
  deleteDeliverableMutation,
  uploadDeliverableMutation,
} from '@/v1.5/features/sessions/api'
import useDeliverable from '@/v1.5/features/sessions/hooks/use-deliverable.hook'
import { useSessionInReview } from '@/v1.5/features/sessions/hooks/use-in-review-session.hook'
import type { DeliverableType } from '@/v1.5/features/sessions/types'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import FileUpload from '@/v1.5/features/ui/components/file-upload/file-upload.vue'
import ArrowIcon from '@/v1.5/features/ui/components/icons/arrow-icon.vue'
import CancelIcon from '@/v1.5/features/ui/components/icons/cancel-icon.vue'
import FileIcon from '@/v1.5/features/ui/components/icons/file-icon.vue'
import LoadingIcon from '@/v1.5/features/ui/components/icons/loading-icon.vue'
import LoadingContent from '@/v1.5/features/ui/components/loading-content/loading-content.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetReviewSessionModal } from '@/v1.5/features/ui/store/modal/modal.selectors'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { DO_NOT_ASK_REVIEW_AGAIN } from '@/v1.5/utils/config/constants'
import { invalidateDeliverable, invalidateSession, invalidateSessions } from '@/v1.5/utils/lib/vue-query'

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const reviewSessionModalRef = selectGetReviewSessionModal(modalsStore)
const { accountRef } = useAccount()
const toast = useToast()
const { t } = useI18n()

const { onClickNextStep } = useSessionInReview()

// the file that the user wants to upload
const fileRef = ref<File | null>(null)

// the deliverable that the user has upload or that has been already uploaded
const deliverableRef = ref<DeliverableType | null>(null)

// if the mentee has no deliverable to upload
const isNoDeliverableRef = ref(false)
function onNoDeliverableCheckboxChange(newValue: boolean) {
  isNoDeliverableRef.value = newValue
}

// is the file currently uploading
const isFileUploadingRef = ref(false)

// the percentage of the file that has been uploaded
const uploadPercentageRef = ref(0)

// the current XHR request (file being uploaded)
const xhrRef = ref<XMLHttpRequest | null>(null)

// the deliverable that has been uploaded by the user
const { deliverableRef: alreadyUploadedDeliverableRef, isPendingRef: isAlreadyUploadedDeliverablePendingRef } =
  useDeliverable({
    userId: computed(() => accountRef.value?.id),
    sessionId: computed(() => reviewSessionModalRef.value.attachedData.sessionId!),
  })

// check if the deliverable already uploaded has been imported
// if it has been CORRECTLY imported, we can display it
watch(alreadyUploadedDeliverableRef, async (deliverable) => {
  if (deliverable?.file) {
    const deliverableImportationData = await confirmDeliverableImport({
      deliverableId: deliverable?.id,
    })

    if (deliverableImportationData.delivrableIsImported) {
      deliverableRef.value = deliverable ?? null
    }
  }
})

// upload progress tracking
function uploadProgress(event: ProgressEvent) {
  if (event.lengthComputable) {
    const percentComplete = (event.loaded / event.total) * 100
    uploadPercentageRef.value = Math.round(percentComplete)
  }
}

const { mutateAsync: uploadDeliverable } = useMutation({
  mutationFn: uploadDeliverableMutation,
})
const { mutateAsync: confirmDeliverableImport } = useMutation({
  mutationFn: checkDeliverableImportationMutation,
})

const { mutate: deleteDeliverable } = useMutation({
  mutationFn: deleteDeliverableMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },

  onSuccess: async () => {
    // if the deliverable has been deleted, we reset the file and the deliverable
    fileRef.value = null
    deliverableRef.value = null
    await invalidateDeliverable({
      userId: accountRef.value!.id!,
      sessionId: reviewSessionModalRef.value.attachedData.sessionId!,
    })
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateSession(reviewSessionModalRef.value.attachedData.sessionId!)
    toast?.success(t('session.reviewModal.addDeliverableModal.fileDeleted'))
  },
})

// use XHR to upload the file and track the progress
async function uploadFileAndTrackProgress() {
  try {
    // if there is no deliverable to upload
    if (isNoDeliverableRef.value) {
      // do stuff
      return
    }

    if (!fileRef.value) {
      return
    }
    const file = fileRef.value
    // we reset the upload percentage and set the file as uploading
    uploadPercentageRef.value = 0
    isFileUploadingRef.value = true
    // we get the upload URL
    const uploadData = await uploadDeliverable({
      mimetype: fileRef.value.type,
      sessionId: reviewSessionModalRef.value.attachedData.sessionId!,
      userId: accountRef.value!.id!,
    })

    deliverableRef.value = uploadData

    // we open the XHR request and send the file
    await new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', uploadData.url)
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
      xhr.setRequestHeader('x-amz-acl', 'public-read')
      xhr.setRequestHeader('Content-Type', file.type)
      xhr.upload.addEventListener('progress', uploadProgress)
      xhr.onload = async () => {
        if (xhr.status === 200) {
          console.log('File uploaded successfully')

          // we confirm the deliverable importation as successful
          await confirmDeliverableImport({ deliverableId: uploadData.id })

          await invalidateSessions({ groupId: selectedGroupIdRef.value! })
          await invalidateSession(reviewSessionModalRef.value.attachedData.sessionId!)
          toast?.success(t('sesion.reviewModal.addDeliverableModal.received'))
          resolve()
        } else {
          console.error('Error uploading file:', xhr.statusText)
          reject(new Error('File upload failed'))
        }
      }
      xhr.onerror = () => {
        console.error('Error uploading file:', xhr.statusText)
        reject(new Error('File upload failed'))
        xhrRef.value = null
      }
      // sending the file
      xhr.send(fileRef.value)
      // assigning the XHR request to the ref
      xhrRef.value = xhr
    })
    // on success, we reset the file as not uploading
    isFileUploadingRef.value = false
  } catch (error) {
    // if an error occurs, we reset the file and the deliverable and we invalidate the deliverable importation
    if (deliverableRef.value) {
      await confirmDeliverableImport({ deliverableId: deliverableRef.value.id })
    }
    deliverableRef.value = null
    fileRef.value = null
    isFileUploadingRef.value = false
    // warn the user
    toast?.error(t('error.common'))
    console.error('Error uploading file:', error)
    throw error // Re-throw the error to propagate it further if needed
  }
}

// when a file is uploaded, we start the upload process
watch(fileRef, () => {
  uploadFileAndTrackProgress()
})

function onDeliverableFileChange(file: File) {
  fileRef.value = file
}

// remove the deliverable file
function removeDeliverableFile() {
  // if the user is currently uploading a file, we cancel the upload
  if (isFileUploadingRef.value) {
    fileRef.value = null
    isFileUploadingRef.value = false
    xhrRef.value?.abort()
  }

  if (!deliverableRef.value) {
    throw new Error('No deliverable to delete')
  }
  deleteDeliverable({
    deliverableId: deliverableRef.value.id,
    sessionId: reviewSessionModalRef.value.attachedData.sessionId!,
    userId: accountRef.value!.id!,
  })
}

function onNext() {
  onClickNextStep()
}

function onClose() {
  modalsStore.toggleModal('review-session', false)
  sessionStorage.setItem(DO_NOT_ASK_REVIEW_AGAIN, 'true')
}
</script>

<style lang="scss">
@import './add-deliverable-modal-content.scss';
</style>
