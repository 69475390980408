// import { useQueries } from '@tanstack/react-query'

import { useQueries } from '@tanstack/vue-query'
import { computed, watch } from 'vue'

import { getUserData, V1_AUTH_USER_QUERY_KEY, V1_USER_DATA_QUERY_KEY, V1GetAuthUser } from '@/v1.5/features/auth/api'
import useIsAuth from '@/v1.5/features/auth/hooks/use-is-auth.hook'
import type { V1AccountDataType, V1AuthUserType, V2AccountDataType } from '@/v1.5/features/auth/types'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'

function V1ToV2DataMiddleware(
  data?: Partial<V1AccountDataType & V1AuthUserType>,
): Partial<V2AccountDataType> | undefined {
  if (!data) return undefined

  return {
    role: data?.role,
    email: data?.email,
    firstName: data?.identity?.firstname,
    lastName: data?.identity?.lastname,
    id: data?.id,
    picture: data?.personalInformation?.profilPicture,
    supportId: data.supportId,
    hasSAML: !!data.samlId,
    hasAcceptedTerms: data.cguAccepted,
  }
}

function useAccount() {
  const { isAuthRef } = useIsAuth()

  const toast = useToast()
  const { t } = useI18n()

  const queryResult = useQueries({
    queries: [
      {
        queryKey: [V1_USER_DATA_QUERY_KEY],
        queryFn: getUserData,
        enabled: isAuthRef,
      },
      {
        queryKey: [V1_AUTH_USER_QUERY_KEY],
        queryFn: V1GetAuthUser,
        enabled: isAuthRef,
      },
    ],
  })

  // watch for errors
  watch(
    () => queryResult.value.map((query) => query.error),
    (errors) => {
      if (errors.some((error) => error)) {
        console.error(errors)
        toast?.error(t('error.common'))
      }
    },
  )

  // merging all data from the queries (that constitute the account data)
  const accountDataRef = computed(() => {
    const userData = queryResult.value[0].data
    const authUserData = queryResult.value[1].data
    return V1ToV2DataMiddleware({
      ...userData,
      ...authUserData,
    })
  })

  // check if any query is pending
  const isPendingRef = computed(() => queryResult.value.some((query) => query.isFetching))

  return {
    accountRef: accountDataRef,
    isPendingRef,
  }
}

export default useAccount
